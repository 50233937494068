/* To left align content */
.projects {
    background-color: rgba(142, 185, 222, 0.15);
    padding-left: 5rem; /* Adjust based on your preference */
}

.carousel{
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
    align-items: left;
}

.headerProjects > h1 {
    font-size: 40px;
    text-align: left;  /* Align to left */
    padding-top: 2%;
}

.headerProjects > p {
    
    font-size: 20px;
    max-width: 5000px;
    text-align: left;  /* Align the text to left instead of using flexbox */
    padding-right: 5rem;  /* Adjust based on your preference */
}

/* Mobile view */
@media (max-width: 768px) {
    .projects {
        padding-left: 20px; /* Left padding for mobile */
    }
    
    .headerProjects > h1, 
    .headerProjects > p {
        text-align: center;
        padding-left: 20px; /* Left padding for mobile */
        padding-right: 20px; /* Right padding for mobile */
    }

    .carousel {
        padding-left: 20px; /* Left padding for mobile */
        padding-right: 20px; /* Right padding for mobile */
    }
}

