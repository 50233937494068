.skill {
    padding: 20px;
  }
  
  .skill > h1 {
    text-align: center;
    padding-bottom: 1%;
  }
  
  .row {
    margin: 0;
  }
  
  .col {
    list-style: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 10px;
  }
  
  ul {
    padding: 0;
  }
  
  li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    text-align: center;
  }