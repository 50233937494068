/* Will need to make hambuger when closing */
.header {
  position: absolute;
  top: 3%; 
  left: 50%; 
  justify-content: center;
  transform: translateX(-50%); 
  list-style: none;
  display: flex;
  gap:  100%; 
  z-index: 1;
  font-size: 22px;
}

.Link {
  text-decoration: none;
  color: white;
  border-bottom: 4px solid rgb(255, 114, 58); /* Sets the underline color */
  padding-bottom: 2px; 
}

.Link {
  text-decoration: none;
  color: white;
  transition: color 0.3s;  /* smooth transition for color changes */
}

/* Hover effect */
.Link:hover {
  color: #FF5733;  /* change color on hover */
}

/* Active (click) effect */
.Link:active {
  color: #FFC300;  /* change color when the link is clicked */
}

@media only screen and (max-width: 800px) {
  .headerQoute,
  .carr {
    flex-basis: 100%;
  }

  .header {
    flex-direction: column;
    align-items: center;
    gap: 2vh;
  }
}