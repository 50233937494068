.App {
  max-width: 2280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}
.product--image {
  width: 100%;
  height: 16em;
  object-fit: cover;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 520px;
  margin: auto 1rem;
  text-align: center;
  font-family: arial;
}

.openModalBtn{
  text-decoration: none;
  color: white;
}
.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  color: rgb(255, 114, 58);
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.card button:hover {
  background-color: #ff5722;
}


@media screen and (max-width: 320px) {
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 520px;
    margin: auto 1rem;
  }

  .product--image {
    width: 100%;
    height: 16em;
    object-fit: cover;
  }
}