.head-line {
    margin-top: 2%;  
}

.skill-img {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}



.skill-btn {
    background: linear-gradient(45deg, rgb(255, 114, 58), #4338CA); /* gradient background */
    color: white; /* white text */
    border: none; /* remove any default borders */
    border-radius: 20px; /* rounded corners */
    cursor: pointer; /* hand cursor on hover */
    transition: all 0.3s; /* smooth transition for hover effects */
}

.skill-btn:hover {
    background: linear-gradient(45deg, #4338CA, #6C63FF); /* swap gradient colors */
    transform: scale(1.05); /* slightly enlarge button */
}

.kyle {
    margin-top: 10%;
    float: left;
    max-width: 40%;
}

.about-container {
    display: flex;
    flex-direction: row;  /* Stack children horizontally by default */
    align-items: center;
    justify-content: center;
    max-width: 2200px;
    margin: 0 auto;  /* Center the container */
    padding-bottom: 5%;
}

.about-body {
    flex-grow: 1;  /* This allows the body content to take the remaining width after allocating space for the image */
}

.bio {
    font-size: 110%;
}

@media (max-width: 768px) {
    .kyle {
        float: none;  /* Clear the float on mobile */
        width: 100%;
        margin-top: 20px;
    }

    .intro {
        font-size: 30px;
    }

    .about-container {
        flex-direction: column;  /* Stack children vertically on mobile */
    }

    .body {
        text-align: center;  /* Optional: To center align the text in the body on mobile */
    }

    .skill-img {
        justify-content: center;  /* Center the skills on mobile */
        padding-right: 5%;
    }

    .skill-img li {
        margin: 5px;  /* Space out the individual skill items a bit */
    }
}