.contact-container {
  padding: 90px 0;
  text-align: center;
}

.box1 {
  background-color: white;
  padding-bottom: 2%;
}
.contact-container h2 {
  font-size: 2em;
  text-align: center;
  margin-bottom: 45px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.notification {
  background-color: #4CAF50; /* green background */
  color: white; /* white text color */
  padding: 16px; /* padding around the text */
  position: fixed; /* fixed position */
  bottom: 30px; /* 30px from the bottom of the screen */
  right: 30px; /* 30px from the right of the screen */
  z-index: 1000; /* ensure it's on top of other content */
  border-radius: 5px; /* rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
  transition: opacity 0.3s, transform 0.3s; /* smooth appearance */
  opacity: 0; /* starts hidden */
  transform: translateY(50px); /* starts a bit below its final position */
  pointer-events: none; /* ensures it doesn't block clicks on other elements */
}

.notification.show {
  opacity: 1; /* fully visible */
  transform: translateY(0); /* at its final position */
}


.contact-form-container {
  flex-grow: 1;
}

.contact-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.icon {
  font-size: 1.5em;
  color: #ff725a;
  position: absolute;
  left: 10px;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px 10px 10px 40px; /* adjusted padding to make space for the icon */
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

textarea {
  height: 250px;
  resize: none;
}

button {
  background-color: #ff725a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
}

button:hover {
  background-color: #ff5722;
}

.send-icon {
  margin-right: 5px;
}

.video-resume-container {
  flex-shrink: 0;
  text-align: left;
}

.video-container {
  margin-bottom: 20px;
}

.resume-button {
  display: inline-block;
  background-color: #ff725a;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2em;
}

.resume-button:hover {
  background-color: #ff5722;
}

@media (max-width: 868px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .contact-form-container,
  .video-resume-container {
    width: 100%;
  }

  .video-container iframe {
    width: 100%;
    height: auto;
  }
}
