.video {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    background-color: black;
}

.video1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: none;
}

/* Media Query for Mobile */
@media only screen and (max-width: 768px) {
    .video {
        height: 50vh;
    }
}

