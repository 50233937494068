video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.hero-container {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Corrected value here */
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    padding-left: 5%; /* Moved padding here from h1 to align everything to the left */
}

.hero-container > h1 {
    color: white;
    font-size: 5rem;  
    margin-top: 0;
}

.highlighted-text {
    color: rgb(255, 114, 58);
}

.qoute {
    padding-left: 0; /* Removed padding here since it's handled by the parent container */
    padding-right: 25%;
    margin-top: 1rem;
    color: white;
    font-size: 1.5rem;  
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.btn-list {
    display: flex; 
    flex-direction: row;
    gap: 1rem; /* To provide space between items, more modern approach */
}

.icon-btn {
    color: rgb(255, 114, 58);
    margin: 0 1rem
}

/* Media queries for responsiveness */
@media (max-width: 768px) {

    .hero-container {
        padding-left: 2.5%; /* Less padding for mobile */
    }

    .hero-container > h1 {
        font-size: 2rem;
    }

    .qoute {
        padding-right: 5%;
        font-size: 1.1rem;
    }

    .icon-btn {
        margin: 0 0.5rem; 
        font-size: .9em;
    }

    .btn-list {
        justify-content: center; /* Center the icons on mobile */
    }

}
