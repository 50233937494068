.modal-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200, 0.5); /* adjusted alpha for semi-transparency */
    position: fixed;
    top: 0; /* add this to ensure the modal covers the entire viewport */
    left: 0; /* add this to ensure the modal covers the entire viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
  
  .modalContainer {
    width: 700px;
    height: 750px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }

  .dataSections {
    text-align: left;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  

  .modalContainer .modal-body {
    flex: 50%;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
    overflow-y: auto; /* Make vertical overflow scrollable */
    padding: 10px; /* Optional: Added for better spacing */
    max-height: 500px; /* Adjust this value according to your design */
}

  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: #000;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }

